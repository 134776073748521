import React from "react";
import LpDemo from "../../../page-components/LpDemo";
import { lineOfBusiness } from "../../../constants";

export default function DemoPageWebPayments() {
  return (
    <LpDemo
      leadType="lead-gen"
      dataLayerEventNameAppendix="-lead"
      formSubHeader="Book more appointments and streamline the checkout process with an end-to-end solution designed for salons, spas, medical practices, and more."
      formId="fcb088ff-731b-48b8-96dd-cca8f1f66504"
      useShortForm
      thankYouPath="/lp/health-and-beauty/thank-you"
      phoneNumber="+13035240930"
      phoneText="(303) 524-0930"
      imageName="hb-lp.jpg"
      lineOfBusiness={lineOfBusiness.RETAIL_AND_SERVICES}
    />
  );
}
